@import "app.scss";
/****************************ARTICLES****************/
.card-columns{
  column-count: 3;

  .card{
    border: 2px solid #f8f9fa;
  }
}
.articles{
  min-height: 100vh;
  background-position: top;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url("/img/poterie2.jpg");
  background-size: cover;
  text-align: center;
  padding-bottom: 100px;
}
.articles-title{
  font-family: $font-family1;
  color: white;
  font-size: 96px;
  text-shadow: 1px 1px 2px #082b34;
  border-bottom: 4px solid white;
  padding-top: 100px;
  width: fit-content;
  margin-bottom: 100px;
}

/**************************** RESPONSIVE *********************************************/

@media (max-width: 575.98px) {
  .articles-title {
    font-size: 50px;
  }
  .card {
    position: initial !important;
  }
  .card-columns{
    column-count: 1;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card {
    position: initial !important;
  }
  .card-body h2{
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card {
    position: initial !important;
  }
  .card-body h2{
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .articles-title {
    padding-top: 150px;
  }
}

@media (min-width: 1200px) {

}

